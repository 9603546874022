<template>
  <modal
    name="modal-preview-table"
    scrollable
    width="100%"
    height="100%"
    :clickToClose="false"
  >
    <div class="modal-preview-cabecalho m-3">
      <div class="back-arrow-squared" @click="fecharModalPreviewTable">
        <i class="flaticon-up-arrow" style="color: #808080 !important"></i>
      </div>
    </div>
    <div class="overflow-auto" style="padding: 24px; max-height: 90vh">
      <div class="paginacao-container">
        <b-pagination
          v-model="paginaAtual"
          :total-rows="rows"
          :per-page="porPaigna"
          aria-controls="previewTable"
          size="md"
        ></b-pagination>
        <b-form-select
          v-model="porPaigna"
          :options="paginaQuantidadeOpcoes"
          class="paginas-select"
          size="md"
        ></b-form-select>

        <b-button
          variant="outline-success"
          class="btn-exportar ml-auto"
          @click="exportarExcel"
          v-if="isAccountAdmin || checkSuperAdmin"
        >
          <i class="flaticon-download"></i>
          Exportar
        </b-button>
      </div>
      <b-table
        id="previewTable"
        :items="tableContent"
        :per-page="porPaigna"
        :current-page="paginaAtual"
        small
      ></b-table>
    </div>
  </modal>
</template>

<script>
import * as XLSX from "xlsx";
import { mapGetters } from "vuex";

export default {
  props: ["tableContent", "tableHeaders"],
  data() {
    return {
      porPaigna: 5,
      paginaAtual: 1,
      paginaQuantidadeOpcoes: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50]
    };
  },
  methods: {
    fecharModalPreviewTable() {
      this.$modal.hide("modal-preview-table");
    },
    formatarDataMenorQue10(data) {
      return data < 10 ? "0" + data : data;
    },
    exportarExcel() {
      const dataHoje = new Date();
      const dataFormatada = `${this.formatarDataMenorQue10(
        dataHoje.getDate()
      )}-${this.formatarDataMenorQue10(
        dataHoje.getMonth() + 1
      )}-${dataHoje.getFullYear()} ${this.formatarDataMenorQue10(
        dataHoje.getHours()
      )}:${this.formatarDataMenorQue10(
        dataHoje.getMinutes()
      )}:${this.formatarDataMenorQue10(dataHoje.getSeconds())}`;
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(this.tableContent);
      XLSX.utils.book_append_sheet(wb, ws, "Preview");
      XLSX.writeFile(wb, `Preview ${dataFormatada}.xlsx`);
    }
  },
  computed: {
    rows() {
      return this.tableContent.length;
    },
    ...mapGetters(["checkSuperAdmin", "isAccountAdmin"])
  }
};
</script>

<style>
.vm--container.scrollable .vm--modal {
  overflow: auto !important;
}

.paginacao-container {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 24px;
  gap: 1rem;
}
.paginacao-container .paginas-select {
  width: 100px !important;
}

.paginacao-container ul {
  margin: 0;
}

.back-arrow-squared {
  border: 1px solid #808080;
  border-radius: 4px;
  transform: rotate(-90deg);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
